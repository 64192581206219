import { HttpClient } from '@angular/common/http';
import { Injectable, signal } from '@angular/core';
import { firstValueFrom } from 'rxjs';

import { FrontConfig } from '@so-many-ways/rest-dtos';

@Injectable()
export class AppConfigService {
  private config = signal<FrontConfig | undefined>(undefined);

  constructor(private http: HttpClient) {}

  /**
   * This methods has to be called within APP_INITIALIZER provider.
   * As we need the configuration to be loaded before displaying the application, it has to return a promise.
   */
  async initialize(): Promise<void> {
    try {
      const config = await firstValueFrom(this.http.get<FrontConfig>('/api/configurations/front'));
      this.config.set(config);
    } catch (error) {
      console.error('Failed to load front configuration', error);
    }
  }

  getConfig() {
    return this.config();
  }
}
