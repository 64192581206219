/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { Injectable, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { DateTime } from 'luxon';
import { filter, Subject } from 'rxjs';

import { isNavigationEnd } from '@smw/front-navigation';

import { Analytics, AnalyticsUserProfile } from './analytics';
import { AnalyticsEvent, EventIdentifier } from './events';
// eslint-disable-next-line vars-on-top, @typescript-eslint/no-explicit-any
declare let Intercom: any;

// Needs to be redefined as field name vary slightly vs AnalyticsUserProfile
interface IntercomUserProfile {
  email: string;
  name: string;
  user_id: string;
  company?: {
    id: string;
    name: string;
  };
  isReal: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class IntercomAnalytics implements Analytics, OnDestroy {
  private unsubscribe$ = new Subject<void>();
  private lastIdentifyCall: number | null = null; // Timestamp of the last identify call
  private delayAfterIdentify = 3000;
  private delayAfterEvents = 1000;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  intercom: any;

  constructor(private router: Router) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    this.intercom = (<any>window).Intercom;
    if ((<any>window).Intercom) {
      (<any>window).Intercom('boot', {
        api_base: 'https://api-iam.intercom.io',
        app_id: 'v6agowhs',
        // Session duration 7 days, aligned with our authentication tokens
        // TODO : define as a global project constant once dev is merged to main
        session_duration: 7 * 24 * 60 * 60 * 1000,
      });
    }

    this.router.events.pipe(filter(isNavigationEnd)).subscribe(() => {
      if ((<any>window).Intercom) {
        // This is necessary for intercoms to fetch data. Doing it every page change is intercom's suggested generic way of ensuring
        // all interactions are pushed at the right time, but doing it after interactions that should trigger a message or product tour might be necessary
        // Update : the intercom "update" called beeing heavily limited (20 per 30 minutes without page refresh), we are skipping this for now, and calling update only after relevant events happen
        // (<any>window).Intercom('update');
      }
    });
  }

  identify(userId: string, userProfile: AnalyticsUserProfile): void {
    if ((<any>window).Intercom) {
      // TODO : do not call update upon identify, only upon signup/sign-in and when there is a real data update, or this risk counting toward the max 20 update per 30 minutes limit.
      // In order to do that we must check intercom cookies/auth tokens duration vs ours to ensure we dont run into edge cases.

      const {
        email,
        firstname = '',
        lastname = '',
        clientId,
        clientName,
        tenant = '',
        isRealUser = true,
      } = userProfile;

      const userData: IntercomUserProfile = {
        email,
        name: `${firstname} ${lastname}`.trim(),
        user_id: userId,
        isReal: isRealUser,
      };

      // Using optional chaining and destructuring together
      if (clientId && clientName) {
        userData.company = {
          id: clientId,
          name: clientName,
        };
      }

      (<any>window).Intercom('update', userData);
      this.lastIdentifyCall = Date.now(); // Set the timestamp on identify call
    }
  }

  disconnectIdentity(): void {
    if ((<any>window).Intercom) {
      (<any>window).Intercom('shutdown');
    }
  }

  async track(event: AnalyticsEvent) {
    // Note: very annoying intercom restriction : calling events with a user ID right after the first update with user id (that transform an anonymous lead ID into a real id), will generate 404 errors
    // (apparently it takes a little bit of time to merge lead/user), and the intercom sdk doesnt not wait for user identify API call to complete before calling event update
    // Support mentionned this was the only way to alleviate the problem, though the better approach may be to implement a real callaback or a backend service that ensure user creation API call is complete, then send only sends events.
    // However too, support was not 100% sure that implementing the event tracking flow from the backend would result in instantaneous
    // For now, delaying only the first trac
    const delayRequired =
      this.lastIdentifyCall && Date.now() - this.lastIdentifyCall < this.delayAfterIdentify;

    const trackLogic = async () => {
      // Intercom has a very low throtle limit on the "update"
      // So we are skipping any event that is not truly necessary to trigger active messages and tours
      // See : https://developers.intercom.com/installing-intercom/web/methods/#intercomupdate
      if (
        event &&
        event.id !== EventIdentifier.PageOpened &&
        event.id !== EventIdentifier.PageClosed &&
        event.id !== EventIdentifier.PasswordLost &&
        event.id !== EventIdentifier.ActionCancelled &&
        event.id !== EventIdentifier.ActionModified &&
        event.id !== EventIdentifier.DocumentDownloaded &&
        event.id !== EventIdentifier.SolutionLinkOpened &&
        event.id !== EventIdentifier.SolutionFiltered &&
        event.id !== EventIdentifier.ConversationOpened &&
        event.id !== EventIdentifier.ConversationEdited
      ) {
        // Note : super strange, in production build the last condition gets concatenated with the rest of the && conditions above if no proper formatting :exploding_head:
        console.log('tracking event:', event);
        const eventData = {
          time: DateTime.now().toUTC().toSeconds(),
          ...event.data,
        };
        try {
          (<any>window).Intercom('trackEvent', event.id, eventData);
          // Give a bit of time for intercom to process event, cf notes above
          // Note : would be much better to at least wait for query to complete, but the intercom SDK doesnt provide callbacks
          await this.delay(this.delayAfterEvents);
          (<any>window).Intercom('update', {
            last_request_at: Math.floor(new Date().getTime() / 1000),
          });
        } catch (e) {
          console.warn("Intercom not defined on 'window' object - event not tracked");
        }
      }
    };

    if (delayRequired) {
      setTimeout(trackLogic, this.delayAfterIdentify); // Apply 1s delay if needed
    } else {
      trackLogic(); // Execute immediately if no delay is required
    }
  }

  delay(time: number) {
    return new Promise((resolve) => setTimeout(resolve, time));
  }

  startTimer(trackedEventId: string): void {
    const a = trackedEventId;
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
